import React, { useEffect, useState } from 'react';
import spinner from 'assets/images/icon-loading-claim.png';
import { useTranslation } from 'react-i18next';
import mp from 'assets/images/logo-mp.svg';
import CheckIcon from 'assets/images/check-circle.png';
import ErrorIcon from 'assets/images/error-circle.png'; // Asegúrate de importar tu icono de error aquí
import { useCreateSubscriptionMutation } from 'store/api';
import { AnimatePresence, motion } from 'framer-motion';

const CardPaymentBrick = ({ onLoadingComplete, account, selectedPlan, setMPSub }) => {
    const [createSubscription] = useCreateSubscriptionMutation();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState(null); 
    useEffect(() => {
        let cardPaymentBrickController;

        const loadMercadoPago = async () => {
            if (!window.MercadoPago) {
                const script = document.createElement('script');
                script.src = 'https://sdk.mercadopago.com/js/v2';
                script.onload = initializeMercadoPago;
                document.body.appendChild(script);
            } else {
                initializeMercadoPago();
            }
        };

        const initializeMercadoPago = () => {

            const mp = new window.MercadoPago(process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY, {
                locale: 'es-AR',
            });
            const bricksBuilder = mp.bricks();

            const renderCardPaymentBrick = async (bricksBuilder) => {
                const settings = {
                    initialization: {
                        amount: 50,
                        payer: {
                            email: account.email,
                        },
                    },
                    customization: {
                        paymentMethods: {
                            maxInstallments: 1,
                        },
                    },
                    callbacks: {
                        onReady: () => {
                            setLoading(false);
                            onLoadingComplete?.();
                        },
                        onSubmit: async (cardFormData) => {
                            try {
                                const resultSuscription = await createSubscription({
                                    plan: selectedPlan,
                                    card_token: cardFormData.token,
                                }).unwrap();

                                if (resultSuscription.status === 'active') {
                                    setStatus('success');
                                    setTimeout(() => {
                                        if (setMPSub) {
                                            setMPSub(false);
                                        } else {
                                            window.location.href = '/profile/account';
                                        }
                                    }, 5000);
                                } else {
                                    setStatus('error');
                                }
                            } catch (error) {
                                console.error('Error al procesar la suscripción:', error);
                                setStatus('error');
                            }
                        },
                        onError: (error) => {
                            console.error('Error en el formulario:', error);
                            setStatus('error');
                        },
                    },
                };

                cardPaymentBrickController = await bricksBuilder.create('cardPayment', 'cardPaymentBrick_container', settings);
            };

            renderCardPaymentBrick(bricksBuilder);
        };

        loadMercadoPago();

        return () => {
            cardPaymentBrickController?.unmount();
        };
    }, [onLoadingComplete]);

    return (
        <div>
            {loading && <LoadingComponent />}
            {!loading && status === null && (
                <div className="bg-white rounded-2xl px-6 py-4 flex flex-row items-center mb-10">
                    <div className="flex flex-col grow">
                        <span className="text-lg text-neutral-black">{t('account.credit_debit')}</span>
                        <span className="text-sm text-neutral-silver-300">
                            Pago gestionado por Mercado Pago
                        </span>
                    </div>
                    <div>
                        <img src={mp} width={104} height={36} alt="Payment Method" />
                    </div>
                </div>
            )}
            
            <div id="cardPaymentBrick_container" style={{ display: loading || status !== null ? 'none' : 'block' }}></div>

            {status === 'success' && (
                <StatusMessage
                    message="Se ha suscrito con éxito!"
                    icon={CheckIcon}
                    redirectMessage="Redirigiendo en 5 segundos..."
                    status={status}
                />
            )}

            {status === 'error' && (
                <StatusMessage
                    message="Algo salió mal. Intenta de nuevo o inténtalo más tarde."
                    icon={ErrorIcon}
                    status={status}
                />
            )}
        </div>
    );
};

const LoadingComponent = () => (
    <div className="loading-spinner flex justify-center items-center h-full">
        <img src={spinner} alt="Loading" width={20} height={20} className="animate-spin" />
    </div>
);

const StatusMessage = ({ message, icon, redirectMessage, status }) => (
    <div className="flex justify-center items-center flex-col">
        <AnimatePresence>
            <motion.div
                className="font-archivo text-center flex flex-col items-center"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0 }}>
                <img 
                    src={icon} 
                    alt="Status Icon" 
                    width="60px"
                    height="60px"
                    className="mb-4" 
                />
                <div
                    className={`text-center ${
                        status === 'success' ? 'text-brand-gold' : 'text-brand-red'
                    }`}
                >
                    {message}
                </div>
                {redirectMessage && <div>{redirectMessage}</div>}
            </motion.div>
        </AnimatePresence>
    </div>
);

export default CardPaymentBrick;
