import { useTranslation } from 'react-i18next';
import { BaseModal } from 'components/BaseModal';
import Button from 'components/Button';
import Input from 'components/Input';
import { useState, useEffect } from 'react';

export default function PromoCodeModal({ promoCode, setPromoCode, handleClose, handleSubmit }) {
    const { t } = useTranslation();
    const [input, setInput] = useState('');

    return (
        <BaseModal
            title='Código Promocional'
            show={promoCode}
            onClose={handleClose}
        >
            <Input
                type="text"
                name="promo_code"
                value={input}
                placeholder={'Escribe tu código aquí...'}
                onChange={(e) => setInput(e.target.value)}
            />
            <div className='flex mt-4 gap-1'>
                <Button
                    text={t('global.close')}
                    style='tertiary'
                    onClick={handleClose}
                />
                <Button
                    style="primary"
                    type="submit"
                    text="Canjear"
                    onClick={(e) => {
                        setPromoCode(input);
                        handleSubmit(e);
                        setInput('')
                    }}
                />
            </div>
        </BaseModal>
    );
}
